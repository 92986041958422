<template>
  <div style="height: 100%">
    <SourceTraceCommodity />
  </div>
</template>

<script>
import { SourceTraceCommodity } from '@/components/console/Index.vue';

export default {
  components: {
    SourceTraceCommodity,
  },
};
</script>
